<template>
  <h1 class="text-center">Blogs</h1>

</template>

<script>
export default {
  name: "Blogs"
}
</script>

<style scoped>

</style>